// back to top
$(document).on("turbolinks:load", function(){
  $('.js-back-to-top').find('.back-to-top_btn').click(function () {
    $('html').animate({ scrollTop: 0 }, 300);
  });
  $(window).on('scroll', function (e) {
    var scroll_height_threshold = 400;
    var $back_top = $('.js-back-to-top');
    if ($(window).scrollTop() > scroll_height_threshold) {
      $back_top.css("display", "flex")
    } else {
      $back_top.css("display", "none")
    }
  });
})
